import { Menu } from '@app/models/menu.model';

export const menuConfig: Menu[] = [
    {
        headerTitle: 'MENU.CONTENT',
        roles: [],
    },
    {
        title: 'MENU.AIRPORTS',
        path: '/dashboard/content/airports',
        icon: 'icofont icofont-airplane-alt',
        routeName: 'airports',
        roles: [],
        name: 'airports',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.BANNERS',
        path: '/dashboard/content/banners',
        icon: 'icofont icofont-book-mark',
        routeName: 'banners',
        roles: [],
        name: 'banners',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.PAGES',
        path: '/dashboard/content/pages',
        icon: 'icofont icofont-file-document',
        routeName: 'pages',
        roles: [],
        name: 'pages',
        type: 'link',
    },
    {
        title: 'MENU.OFFERS',
        path: '/dashboard/content/offers',
        icon: 'icofont icofont-sale-discount',
        routeName: 'offers',
        roles: [],
        name: 'offers',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.MORE_LINKS',
        path: '/dashboard/content/moreLinks',
        icon: 'icofont icofont-ui-social-link',
        routeName: 'moreLinks',
        roles: [],
        name: 'moreLinks',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.INTRO_SLIDES',
        path: '/dashboard/content/introSlides',
        icon: 'icofont icofont-scroll-long-right',
        routeName: 'introSlides',
        roles: [],
        name: 'introSlides',
        type: 'link',
        active: false,
    },
    {
        headerTitle: 'MENU.NOTIFICATIONS',
        roles: ['admin'],
    },
    {
        title: 'MENU.TOPICS',
        path: '/dashboard/content/topics',
        icon: 'icofont icofont-dashboard-web',
        routeName: 'topics',
        roles: ['admin'],
        name: 'topics',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.FLIGHTS',
        path: '/dashboard/content/flightNotifications',
        icon: 'icofont icofont-ui-flight',
        routeName: 'flightNotifications',
        roles: ['admin'],
        name: 'flightNotifications',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.BROADCASTS',
        path: '/dashboard/content/broadcasts',
        icon: 'icofont icofont-ui-messaging',
        routeName: 'broadcasts',
        roles: ['admin'],
        name: 'broadcasts',
        type: 'link',
        active: false,
    },
    {
        subSeparator: true,
        roles: ['admin'],
    },
    {
        title: 'MENU.GEO_LOCATION',
        path: '/dashboard/content/geofences',
        icon: 'icofont icofont-map-pins',
        routeName: 'geofences',
        roles: ['admin'],
        name: 'geofences',
        type: 'link',
        active: false,
    },
    {
        headerTitle: 'MENU.CONFIG',
        roles: ['admin'],
    },
    {
        title: 'MENU.EQUIPMENT',
        path: '/dashboard/content/equipment',
        icon: 'icofont icofont-ui-flight',
        routeName: 'equipment',
        roles: ['admin'],
        name: 'equipment',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.FARE_FIELDS',
        path: '/dashboard/content/fareFields',
        icon: 'icofont icofont-bill',
        routeName: 'fareFields',
        roles: ['admin'],
        name: 'fareFields',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.FARES',
        path: '/dashboard/content/fares',
        icon: 'icofont icofont-cur-dollar',
        routeName: 'fares',
        roles: ['admin'],
        name: 'fares',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.COUNTRIES',
        path: '/dashboard/content/featuredCountries',
        icon: 'icofont icofont-flag',
        routeName: 'featuredCountries',
        roles: ['admin'],
        name: 'featuredCountries',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.TERMS',
        path: '/dashboard/content/terms',
        icon: 'icofont icofont-shield',
        routeName: 'terms',
        roles: ['admin'],
        name: 'terms',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.IMPORTANT_REMINDERS',
        path: '/dashboard/content/importantReminders',
        icon: 'icofont icofont-ui-alarm',
        routeName: 'importantReminders',
        roles: ['admin'],
        name: 'importantReminders',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.USERS',
        path: '/dashboard/content/users',
        icon: 'icofont icofont-people',
        routeName: 'users',
        roles: ['admin'],
        name: 'users',
        type: 'link',
        active: true,
    },
];
